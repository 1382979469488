import $ from "jquery";
import "slick-carousel";
import * as bootstrap from "bootstrap";

const initSlickSlider = () => {
  $("#entitesCarousel .carousel-inner").slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    nextArrow: $("#slick-btn-wrap .next-btn"),
    prevArrow: $("#slick-btn-wrap .prev-btn"),
    responsive: [
      {
        breakpoint: 1400, // xxl
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992, // md
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576, // sm
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
};

const initTitleAnimated = () => {
  const titleAnimatedList = document.querySelector(".title-animated-list");
  if (!titleAnimatedList) {
    return;
  }

  const items =
    titleAnimatedList.querySelectorAll(".title-animated-item") || [];
  if (!items.length) return;

  const firstItemIndex = items.length - 1;
  // animation-duration dans main.scss en ms
  const animationDuration = 1200;

  let currentItemIndex = firstItemIndex;
  let activeItem = null;

  setInterval(() => {
    // console.log("setInterval", currentItemIndex, activeItem);
    if (activeItem) {
      activeItem.classList.remove("active");
    }

    items[currentItemIndex].classList.add("active");
    activeItem = items[currentItemIndex];
    currentItemIndex--;

    if (currentItemIndex < 0) {
      currentItemIndex = firstItemIndex;
    }
  }, animationDuration);
};

(() => {
  initTitleAnimated();
  initSlickSlider();
  const carousel = new bootstrap.Carousel(
    document.getElementById("smartStoriesCarousel")
  );

  const toggledNavElement = document.getElementById("navbarSupportedHeader");

  toggledNavElement.addEventListener("show.bs.collapse", (evt) => {
    console.log('show');
    const navbar = evt.target.closest(".navbar");
    navbar.classList.add("responsive-open");
  });

  toggledNavElement.addEventListener("hide.bs.collapse", (evt) => {
    const navbar = evt.target.closest(".navbar");
    navbar.classList.remove("responsive-open");
  });

  document.addEventListener("click", (evt) => {
    const { target } = evt;
    if (target.closest(".nav-item")) {
      document.querySelector('.navbar-toggler')?.click();
    }
  });
})();
